h1.blue {
  padding: 6px 0 3px 8px;
  color: #30a7e9;
}
h3.blue {
  font-size: 28px;
  line-height:45px;
  color:#30a7e9;
  font-weight:700;
}
h4.blue {
  color:#30a7e9;
  line-height:36px;
}
cite.quote-dark {
  a {
    color: #fff;
  }
}

.banner {
  height: 768px;
}

.narrow-heading {
  width: 60%;
}

.light-link {
  color: #0863d0;
}
.white-link {
  color: #fff;
}

input#url {
  display: none;
}

#form-errors {
  margin-top: 16px;
  p {
    font-weight: bold
  }

  .input-error {
    color: crimson;
  }
}

#form-submission-confirmation {
  margin-top: 16px;
  p {
    color: darkgreen;
  }
}

.privacy {
  ul li {
    list-style-type: circle;
  }
}

/* Override default style.css so that we can have absolute paths
   This is required because Jekyll puts permalink pages inside sub-directories.
 */
.intro1 {
  background: #262626 url(/assets/images/bg/bright-bulb-color.jpg) no-repeat center;
  background-size: cover;
  padding: 230px 0 200px;
}
.intro3 {
  background: #262626 url(/assets/images/bg/bright-bulb-color.jpg) no-repeat center;
  background-size: cover;
  padding: 114px 0 0;
}

.page_head {
  background: url(/assets/images/bg/bright-bulb-color.jpg) no-repeat top center;
  background-size: cover;
  padding: 160px 0 80px;
}
.page_head_alt {
  background: url(/assets/images/parallax/1.jpg) no-repeat top center;
  background-size: cover;
  padding: 160px 0 80px;
}
.page_head_alt h3 {
  color: #fff;
  font-size: 55px;
  font-weight: 700;
  margin: 0 0 10px;
}

.parallax-content {
  background: url(/assets/images/parallax/1.jpg) no-repeat center fixed;
  background-size: cover;
  padding: 55px 0;
  position: relative;
}
/* make top padding smaller */
.navbar-lg .navbar-nav > li > a {
  padding: 10px 12px 35px;
  font-size: 13px;
  line-height: 1.6;
  color: #fff;
  text-transform: uppercase;
}
.navbar-lg .navbar-brand {
  padding: 10px 0 0;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
}
